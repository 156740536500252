@import "@/styles/globals";
//  ┬┌┬┐┌─┐┌─┐┬─┐┌┬┐┌─┐
//  ││││├─┘│ │├┬┘ │ └─┐
//  ┴┴ ┴┴  └─┘┴└─ ┴ └─┘

@import './vue-general';
@import './auth-bootstrap';
@import '~noty/src/noty';
@import '~noty/src/themes/bootstrap-v4';

//  ┌─┐┌─┐┌┐┌┌┬┐┌─┐
//  ├┤ │ ││││ │ └─┐
//  └  └─┘┘└┘ ┴ └─┘

@font-face {
  font-family: 'open_sansregular';
  src: url('../fonts/opensans-regular-webfont.eot');
  src: url('../fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/opensans-regular-webfont.woff2') format('woff2'),
  url('../fonts/opensans-regular-webfont.woff') format('woff'),
  url('../fonts/opensans-regular-webfont.ttf') format('truetype'),
  url('../fonts/opensans-regular-webfont.svg#open_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

// vertical-center a div
html {
  height: 100%;
}

body {
  font-family: 'open_sansregular';
  height: 100%;
}

#app {
  height: 100%;
}

.vertical-center {
  font: 0/0 a; // remove the gap between inline(-block) elements
  height: 100%;
  margin-bottom: 0; // Remove the default bottom margin of .jumbotron
  text-align: center;  // align the inline(-block) elements horizontally
  width: 100%;

  > .container {
    display: inline-block;
    font: 16px/1 "open_sansregular"; // <-- reset the font property
    max-width: 100%;
    vertical-align: middle; // vertical alignment of the inline element
  }
}

// create a full-height inline block pseudo=element
.vertical-center::before {
  content: ' ';
  display: inline-block;
  height: 100%;
  vertical-align: middle; // vertical alignment of the inline element
}

@media (max-width: 768px) {
  .vertical-center::before {
    // height: auto;
    display: none;
  }
}

// the form
.form-centered {
  margin: 0 auto;
  max-width: 330px;
  padding: 15px;

  img {
    margin: 0 auto;
    margin-bottom: 15px;
  }

  small {
    display: block;
  }

  input {
    white-space: pre-line;
  }

  .input-group-prepend {
    margin-bottom: 20px;

    label {
      font-weight: normal;
      margin-bottom: 15px;
    }

    .input-group-text {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .form-control {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  .form-control {
    box-sizing: border-box;
    font-size: 16px;
    height: auto;
    padding: 10px;
    position: relative;
  }

  .form-control:focus {
    z-index: 2;
  }
}
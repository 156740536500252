$black: rgb(0, 0, 0);
$grey: rgba(0, 0, 0, .1);
$dark-grey: rgb(108, 117, 125);
$white: rgb(255, 255, 255);

$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;